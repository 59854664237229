module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"backgroundColor":"rgba(0, 0, 0, 0.7)"},"content":{"position":"relative","width":"100%","background":"transparent","WebkitOverflowScrolling":"touch","borderRadius":0,"border":0,"padding":0,"top":0,"left":0,"right":0,"bottom":0}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IPG DXTRA","short_name":"DXTRA","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/favicon-32x32.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5VH2STJ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
